<script setup lang="ts">
const emit = defineEmits(["closeModal"]);

function onContinue(): void {
  localStorage.setItem("cookieAccepted", "true");
  emit("closeModal");
}
</script>

<template>
  <div class="cookies">
    <div class="container">
      <h1>Zenapptic uses cookies to create a better experience for you</h1>

      <div class="body">
        <p>
          We use cookies on our website to collect information on how you interact with the website
          and to assist with our marketing efforts. By clicking "CONTINUE", you agree to the storing
          of cookies on your computer. For more information, please see our
          <a href="">Privacy Policy</a>.
        </p>

        <p class="text-right">
          <a class="button" @click="onContinue">CONTINUE</a>
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "../assets/scss/vars";
.cookies {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 50%;
  position: fixed;
  transform: translateX(-50%);
  width: 100vw;
  z-index: 1000;

  .container {
    animation: enterScreen 0.75s forwards;
    background: rgb(28, 24, 25);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 2px 10px 2px rgba(255, 255, 255, 0.3);
    color: white;
    max-width: 1000px;
    padding: 3rem 2rem 1.5rem 2rem;
    transform: translateY(100%);
    width: 100%;

    @keyframes enterScreen {
      0% {
        transform: translateY(100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    @media only screen and (max-width: 767px) {
      bottom: 70px;
    }

    h1 {
      font-size: min(5vw, 2.2rem);
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
    }

    .body {
      p {
        font-size: min(3vw, 1.5rem);
        padding-bottom: 1rem;
        padding-left: 1rem;
      }

      a {
        color: #fff;
      }

      a.button {
        background: vars.$primary-color;
        border: 0;
        border: 2px vars.$primary-color solid;
        border-radius: 10px;
        color: black;
        cursor: pointer;
        font-size: min(4vw, 1.6rem);
        font-weight: 600;
        padding: 0.75rem 1.25rem;
        transition: all 0.25s;

        &:hover {
          background: transparent;
          color: vars.$primary-color;
        }
      }
    }
  }
}
</style>
