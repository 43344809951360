export const useUtils = () => {
  const getGuid = (): string => {
    const guidCookie = useCookie(`${useRuntimeConfig().public.appName}_guid`);
    if (import.meta.client) {
      if (guidCookie.value) {
        return guidCookie.value;
      }

      let d = new Date().getTime() + performance.now();

      const newGuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });

      guidCookie.value = newGuid;

      return guidCookie.value;
    } else {
      return "none";
    }
  };

  const getSessionId = (): string => {
    let sessionId = sessionStorage.getItem("sessionId") || undefined;

    if (!sessionId) {
      let d = new Date().getTime() + performance.now();

      sessionId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      });

      sessionStorage.setItem("sessionId", sessionId);
    }

    return sessionId;
  };

  const getInitials = (value: string): string => {
    return value
      .match(/(\b\S)?/g)
      .join("")
      .toUpperCase();
  };

  const getYear = (): number => {
    return new Date().getFullYear();
  };

  const isMobileDevice = () => {
    return isAndroid() || isIos() || isIpadOs() ? true : false;
  };

  function isAndroid() {
    return /Android/i.test(navigator.userAgent);
  }

  function isIos() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  function isIpadOs() {
    return /iPad/.test(navigator.userAgent);
  }

  const getOrientation = (): string => {
    const portrait = window.matchMedia("(orientation: portrait)").matches;
    return portrait ? "portrait" : "landscape";
  };

  return {
    getGuid,
    getSessionId,
    getInitials,
    getYear,
    isMobileDevice,
    isIpadOs,
    getOrientation,
  };
};
