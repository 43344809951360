<script setup lang="ts">
const isMenuActive = ref(false);
const currentRoute = useRoute();
const scrollingToTarget = useScrollingToTarget();
let scrollTimeout: ReturnType<typeof setTimeout>;

const leftArrow = computed(() => {
  return `url(${useCdnUrl()}/assets/icons/nav-end-left.png) no-repeat`;
});

const rightArrow = computed(() => {
  return `url(${useCdnUrl()}/assets/icons/nav-end-right.png) no-repeat`;
});

function onItemClick(): void {
  isMenuActive.value = false;
  scrollingToTarget.value = true;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    scrollingToTarget.value = false;
  }, 2000);
}
</script>

<template>
  <header>
    <div class="nav-bar">
      <div class="nav-container">
        <div class="brand" @click="onItemClick">
          <NuxtLink to="/#feature" aria-label="Home">
            <img :src="`${useCdnUrl()}/assets/logos/zen-2023-header.svg`" alt="Zenapptic.ai" />
          </NuxtLink>
        </div>

        <div class="menu-row" :class="{ active: isMenuActive }">
          <nav>
            <ul>
              <li @click="onItemClick">
                <NuxtLink
                  to="/#aboutUs"
                  aria-label="about us"
                  :class="{ active: currentRoute.hash === '#aboutUs' }"
                  >ABOUT US</NuxtLink
                >
              </li>
              <li class="dropdown" @click="onItemClick">
                <a
                  href="#"
                  class="trigger"
                  aria-label="solutions"
                  :class="{ active: currentRoute.hash === '#solutions' }"
                  >SOLUTIONS</a
                >
                <div class="dropdown-content">
                  <NuxtLink to="/solutions/experiential-spaces">Experiential Spaces</NuxtLink>
                  <NuxtLink to="/solutions/digital-workplace">Digital Workplace</NuxtLink>
                  <NuxtLink to="/solutions/experience-centers">Experience Centers</NuxtLink>
                  <NuxtLink to="/solutions/virtual-production">Virtual Production</NuxtLink>
                </div>
              </li>
              <li @click="onItemClick">
                <NuxtLink to="/#platform" :class="{ active: currentRoute.hash === '#platform' }"
                  >PLATFORM</NuxtLink
                >
              </li>
              <li @click="onItemClick">
                <NuxtLink to="/creative-services" aria-label="Creative Services Page"
                  >CREATIVE SERVICES</NuxtLink
                >
              </li>
              <li @click="onItemClick">
                <NuxtLink to="/#contact" aria-label="contact us">CONTACT US</NuxtLink>
              </li>
            </ul>
          </nav>
        </div>
        <div class="toggle-menu" @click="isMenuActive = !isMenuActive">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
@use "../assets/scss/vars";
header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  .nav-bar {
    backdrop-filter: blur(9px);
    background: rgba(28, 24, 25, 0.95);
    padding: 1.5rem;

    @media only screen and (max-width: 940px) {
      padding: 1rem;
    }

    .nav-container {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: auto 1fr auto;
      margin: auto;
      max-width: 1366px;
      transition: all 0.5s ease-out;

      .brand {
        a {
          img {
            height: 56px;

            @media only screen and (max-width: 940px) {
              height: 40px;
            }
          }
        }
      }

      .menu-row {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr auto;
        overflow: visible;

        @media only screen and (max-width: 940px) {
          backdrop-filter: blur(9px);
          background: rgba(28, 24, 25, 0.95);
          display: grid;
          height: 100vh;
          max-width: 480px;
          opacity: 0;
          position: absolute;
          right: -100%;
          top: 100%;
          transition: all 0.5s ease-out;
          width: 90%;
          z-index: 100;

          &.active {
            display: grid;
            opacity: 1;
            right: 0;
          }
        }

        nav {
          position: relative;
          @media only screen and (max-width: 940px) {
            position: absolute;
            top: 2rem;
          }

          ul {
            display: grid;
            grid-auto-flow: column;
            justify-items: end;
            list-style-type: none;
            margin: 0;
            padding: 0 2rem 0 8rem;

            @media only screen and (max-width: 940px) {
              grid-auto-flow: row;
              justify-items: start;
              padding: 0 4rem;
            }

            li {
              align-items: center;
              display: flex;
              padding-left: 1rem;
              padding-right: 1rem;

              &.dropdown {
                position: relative;

                @media only screen and (max-width: 820px) {
                  align-items: flex-start;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                }

                @media only screen and (min-device-width: 320px) and (max-device-width: 932px) and (orientation: landscape) {
                  align-items: flex-start;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                }

                &:hover .dropdown-content {
                  display: block !important;
                }

                .dropdown-content {
                  background-color: rgb(50, 50, 50);
                  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                  display: none;
                  min-width: 160px;
                  position: absolute;
                  top: 100%;
                  width: 10rem;
                  z-index: 20000;

                  a {
                    color: white;
                    display: block;
                    padding: 12px 16px;
                    text-decoration: none;

                    &:hover {
                      text-decoration: underline;
                    }
                  }

                  @media only screen and (min-device-width: 320px) and (max-device-width: 932px) and (orientation: landscape) {
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    position: relative !important;
                    top: 0;
                    width: 100%;

                    a {
                      display: inline-block;
                    }
                  }

                  @media only screen and (max-width: 820px) {
                    background: transparent;
                    display: flex;
                    flex-direction: column;
                    position: relative !important;
                    top: 0;
                    width: 100%;

                    a {
                      display: inline-block;
                    }
                  }
                }

                @media only screen and (min-width: 821px) {
                  &:before {
                    background: v-bind(leftArrow);
                    content: "";
                    display: block;
                    height: 10px;
                    margin-top: 0.6rem;
                    width: 10px;
                  }

                  &:after {
                    background: v-bind(rightArrow);
                    content: "";
                    display: block;
                    height: 10px;
                    margin: 0.6rem 0 0 0.75rem;
                    width: 10px;
                  }
                }
              }

              a {
                color: white;
                cursor: pointer;
                font-size: 1.5rem;
                font-weight: 500;
                text-decoration: none;
                transition: all 0.25s;

                &:hover {
                  color: vars.$primary-color;
                }

                &.active {
                  color: vars.$primary-color;
                  font-weight: 700;
                }
              }

              @media only screen and (max-width: 940px) {
                padding: 1rem 0;
              }

              @media only screen and (max-width: 940px) and (orientation: landscape) {
                padding: 0.5rem 0;
              }
            }
          }
        }
      }

      .toggle-menu {
        cursor: pointer;
        height: 20px;
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;

        @media only screen and (min-width: 941px) {
          display: none;
        }

        div {
          background: white;
          height: 2px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>
